/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

function EventTile(props) {
  const {
    event, onConfirm, id, onUpdate,
  } = props;

  const handleChange = (key) => {
    onUpdate(key);
  };

  return (
    <div>
      <Paper elevation={1} className="games-banner paper-padding">
        <Grid container spacing={3}>
          <Grid xs={12} sm={11}>
            <FormGroup style={{ display: 'inline-block' }}>
              <FormControlLabel
                control={<Android12Switch checked={event.enabled} onChange={() => handleChange(id)} />}
                label=""
              />
            </FormGroup>
            <h3 style={{ display: 'inline-block' }}>{event.name} ({event.abbrv})</h3>
            <p>{event.description}</p>
          </Grid>
          <Grid xs={12} sm={1}>
            <HighlightOffIcon onClick={() => { onConfirm(event.name); }} style={{ cursor: 'pointer', float: 'right', color: '#004b84' }} />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
export default EventTile;
