/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useSelector } from 'react-redux';

function Submit() {
  const gameId = useSelector((state) => state.gameDetails.id);
  return (
    <p>
      This is where you will see game data.
      ID: {gameId}
    </p>
  );
}
export default Submit;
