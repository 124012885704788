import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';

function Athletes() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        Athletes Page
      </Grid>
    </Grid>
  );
}
export default Athletes;
