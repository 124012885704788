/* eslint-disable prefer-const */
/* eslint-disable no-multi-assign */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import EventTile from './EventTile';
import {
  updateEvents,
} from '../../slices/gamesDetailsSlice';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function Events() {
  const events = useSelector((state) => state.gameDetails.events);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = (event) => {
    setModalOpen(true);
    setSelectedEvent(event);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleOnUpdate = (key) => {
    let updatedEvents = events.map((event, i) => {
      let tempEvent;
      if (i === key) {
        tempEvent = {
          ...event,
          enabled: !event.enabled,
        };
        return tempEvent;
      }
      return event;
    });

    dispatch(updateEvents(updatedEvents));
  };

  return (
    <>
      <Dialog
        open={modalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          Remove
          {' '}
          {selectedEvent}
          ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure youd like to remove the following event:
            {' '}
            <strong>{selectedEvent}</strong>
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Yes</Button>
          <Button onClick={handleClose}>No</Button>
        </DialogActions>
      </Dialog>

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

        <Fab
          size="medium"
          color="secondary"
          aria-label="add"
          style={{
            position: 'fixed', bottom: 10, right: 10, 'z-index': '99999', 'background-color': '#1A2027',
          }}
        >
          <AddIcon />
        </Fab>

        {
        events.map((event, i) => (
          <Grid xs={12} sm={6}>
            <EventTile event={event} onConfirm={() => handleClickOpen(event.name)} id={i} onUpdate={(key) => handleOnUpdate(key)} />
          </Grid>
        ))
      }
      </Grid>
    </>
  );
}
export default Events;
