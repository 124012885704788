import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { TextField } from '@mui/material';
import { getBackgroundTartan } from '../utils/getRandomHsl';
import ImageUploader from '../../../components/PracticeGroups/ImageUploader';
import styles from './PracticeModal.css';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const inputStyles = {
  margin: '10px',
  width: '100%',
  display: 'block',
};

export default function CustomizedDialogs(props) {
  const {
    isOpen, name, description, contact, onClose, address, link, instagram, image,
  } = props;

  const formattedName = name.replaceAll('-', ' ');
  const imgUrl = `https://api.hgscores.com/images/practice-groups/${formattedName.replace(/\s+|-|--/g, '-').toLowerCase()}.png`;

  const [groupImage, setGroupImage] = React.useState('');

  return (
    <BootstrapDialog
      maxWidth="lg"
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
    >
      <DialogTitle
        sx={{
          m: 0, p: 2, ...getBackgroundTartan(), color: '#FFF', borderBottom: '5px solid #000',
        }}
        id="customized-dialog-title"
      >
        <span className="test">{name}</span>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'white',
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        <Typography gutterBottom>
          With the password for this group, you can change it's details, or delete it.
        </Typography>

        <div className="form-container">
          <div className="form-input-container">
            <TextField id="outlined-basic" fullWidth label="Password" variant="outlined" className="practice-group-add-input" sx={inputStyles} placeholder="***" />

            <TextField
              fullWidth
              id="outlined-basic"
              label="Group Name"
              variant="outlined"
              className="practice-group-add-input"
              sx={inputStyles}
              defaultValue={name}
            />

            {/* <TextField id="outlined-basic" label="Address" variant="outlined" className="practice-group-add-input" fullWidth sx={inputStyles} defaultValue={address} /> */}

            <TextField id="outlined-basic" label="Contact Email" fullWidth variant="outlined" className="practice-group-add-input" sx={inputStyles} defaultValue={contact} />

            <TextField id="outlined-basic" label="Website Link" fullWidth variant="outlined" className="practice-group-add-input" sx={inputStyles} defaultValue={link} />

            {/* <TextField id="outlined-basic" label="Instagram" fullWidth variant="outlined" className="practice-group-add-input" sx={inputStyles} defaultValue={instagram} /> */}

            <TextField id="outlined-basic" label="description" fullWidth variant="outlined" className="practice-group-add-input" multiline rows={4} defaultValue={description} sx={inputStyles} />

            <ImageUploader currentGroupImageUrl={imgUrl} setGroupImage={setGroupImage} groupImage={groupImage} />

          </div>
        </div>

      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="error">
          Delete
        </Button>
        <Button autoFocus onClick={onClose}>
          Save changes
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
