import React from 'react';
import ContactForm from '../components/InDev/ContactForm';
import GridContainer, { GridItem } from '../components/Shared/Grid/GridContainer';

function Contact() {
  return (
    <GridContainer>
      <GridItem sm={5}>
        <ContactForm />
      </GridItem>
    </GridContainer>
  );
}
export default Contact;
