/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import GameDetails from '../components/AddGame/GameDetails';
import Advanced from '../components/AddGame/Advanced';
import Submit from '../components/AddGame/Submit';
import Events from '../components/AddGame/Events';
import Athletes from '../components/AddGame/Athletes';
import Classes from '../components/AddGame/Classes';
import Info from '../components/AddGame/Info';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function AddGame() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Tabs value={value} onChange={handleChange} centered>
            <Tab label="Info" />
            <Tab
              label="Game Details"
            />
            <Tab label="Advanced" />
            <Tab label="Classes" />
            <Tab label="Athletes" />
            <Tab label="Events" />
            <Tab label="Submit" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <Info />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <GameDetails />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Advanced />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Classes />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Events />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Athletes />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <Submit />
          </TabPanel>
        </Grid>
      </Grid>
    </Box>
  );
}
export default AddGame;
