import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';

export default function ImageUploader(props) {
  const {
    groupImage, setGroupImage, currentGroupImage, currentGroupImageUrl,
  } = props;

  return (
    <>
      {
        groupImage && (
          <div className="chosen-file-container">
            <CloseIcon sx={{ color: 'red' }} onClick={() => setGroupImage(false)} className="remove-file" />
            { (groupImage?.target?.files?.[0]?.name.length > 25)
              // Group image name > 25 characters, concat and append the extension.
              ? `${groupImage?.target?.files?.[0]?.name.slice(0, 25 - 1)}...${groupImage?.target?.files?.[0]?.name.split('.')[1]}` : `${groupImage?.target?.files?.[0]?.name}`}
            <div className="chosen-file">
              <img
                className="practice-group-modal-image"
                alt="not found"
                width="250px"
                src={URL.createObjectURL(groupImage?.target?.files?.[0])}
              />
            </div>
          </div>
        )
      }

      {
        !groupImage && !currentGroupImageUrl && (
          <Button
            variant="contained"
            component="label"
          >
            Add Group Image
            <input
              type="file"
              hidden
              onChange={(e) => setGroupImage(e)}
              accept=".jpg,.png,.gif,.svg"
            />
          </Button>
        )
      }

      {
        currentGroupImageUrl && !groupImage && (
          <>
            <img src={currentGroupImageUrl} alt="practice group" className="practice-group-modal-image" />
            <Button
              variant="contained"
              component="label"
              sx={{ marginLeft: '10px' }}
            >
              Add Group Image
              <input
                type="file"
                hidden
                onChange={(e) => {
                  setGroupImage(e);
                }}
                accept=".jpg,.png,.gif,.svg"
              />
            </Button>
          </>
        )
      }
    </>
  );
}
