/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import AddAthlete from './AddAthlete';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function Athletes() {
  const classes = useSelector((state) => state.gameDetails.classes);
  const [value, setValue] = useState(0);
  const listOfclasses = [...new Set([...classes.men, ...classes.women])];

  console.log(`list of classes${listOfclasses}`);
  console.log('men', classes.men);
  console.log('women', classes.women);
  console.log('all', classes);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const determineGenders = (clss) => {
    if (classes.men.includes(clss) && classes.women.includes(clss)) return { men: true, women: true };
    if (classes.men.includes(clss) && !classes.women.includes(clss)) return { men: true, women: false };
    if (!classes.men.includes(clss) && classes.women.includes(clss)) return { men: false, women: true };
    return { men: false, women: false };
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Tabs value={value} onChange={handleChange} centered>
            <Tab
              label="Info"
            />
            {
              listOfclasses.map((cls) => <Tab label={cls} key={cls} />)
            }
          </Tabs>
          <TabPanel value={value} index={0}>

            <Grid2 container spacing={2}>
              <Grid item xs={12} sm={7} smOffset={2}>
                <h3>Entering Athletes</h3>
                <p>
                  Entering athletes is fast and easy. HG Score will only ask you to add athletes to classes you have specified for this games. You can search through our database of athletes, or add a new athlete.
                </p>
              </Grid>
            </Grid2>

          </TabPanel>
          {
            listOfclasses.map((cls, i) => <TabPanel value={value} index={i + 1}><AddAthlete cls={cls} genders={determineGenders(cls)} /></TabPanel>)
          }
        </Grid>
      </Grid>
    </Box>
  );
}
export default Athletes;
