/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { useSelector, useDispatch } from 'react-redux';
import AthleteAutoComplete from './AthleteAutoComplete';
import getMockAthletes from '../../data/games/highlands/athletes/getMockAthletes';
import Classes from './Classes';
import AthleteInformation from './AthleteInformation';

function AddAthlete(props) {
  const [athlete, setAthlete] = useState(false);
  const [maleAthlete, setMaleAthlete] = useState(false);
  const [femaleAthlete, setFemaleAthlete] = useState(false);

  const classes = useSelector((state) => state.gameDetails.classes);
  const { cls } = props;

  const renderAthleteAutoComplete = (cls) => {
    console.log('rendering autocomplete for classes', classes, cls);

    const handleOnSelectedAthlete = (athlete, gender) => {
      console.log(athlete, gender);
    };

    if (classes.men.includes(cls) && classes.women.includes(cls)) {
      return (
        <>
          <Grid item xs={12} sm={6} smOffset={0}>
            <h2>
              Add Athletes for the
              {' '}
              {cls}
              {' '}
              Women's
              class.
            </h2>
            <AthleteAutoComplete cls={cls} athletes={getMockAthletes(cls, 'women')} setAthlete={(athlt) => setFemaleAthlete(athlt)} selectedAthlete={femaleAthlete} />
            <AthleteInformation athlete={femaleAthlete} cls={cls} gender="women" onSelectedAthlete={(athlete, gender) => handleOnSelectedAthlete(athlete, gender)} selectedAthlete={femaleAthlete} />
          </Grid>

          <Grid item xs={12} sm={6} smOffset={0}>
            <h2>
              Add Athletes for the
              {' '}
              {cls}
              {' '}
              Men's
              class.
            </h2>
            <AthleteAutoComplete cls={cls} athletes={getMockAthletes(cls, 'men')} setAthlete={(athlt) => setMaleAthlete(athlt)} selectedAthlete={maleAthlete} />
            <AthleteInformation athlete={maleAthlete} cls={cls} gender="men" onSelectedAthlete={(athlete, gender) => handleOnSelectedAthlete(athlete, gender)} selectedAthlete={maleAthlete} />
          </Grid>
        </>
      );
    }

    if (classes.women.includes(cls) && !classes.men.includes(cls)) {
      return (
        <Grid item xs={12} sm={8} smOffset={1}>
          <h2>
            Add Athletes for the
            {' '}
            {cls}
            {' '}
            Women's
            class.
          </h2>
          <AthleteAutoComplete cls={cls} athletes={getMockAthletes(cls, 'women')} setAthlete={(athlt) => setFemaleAthlete(athlt)} selectedAthlete={femaleAthlete} />
          <AthleteInformation athlete={femaleAthlete} cls={cls} gender="women" onSelectedAthlete={(athlete, gender) => handleOnSelectedAthlete(athlete, gender)} selectedAthlete={femaleAthlete} />
        </Grid>
      );
    }

    if (classes.men.includes(cls) && !classes.women.includes(cls)) {
      return (
        <Grid item xs={12} sm={8} smOffset={1}>
          <h2>
            Add Athletes for the
            {' '}
            {cls}
            {' '}
            Men's
            class.
          </h2>
          <AthleteAutoComplete cls={cls} athletes={getMockAthletes(cls, 'men')} setAthlete={(athlt) => setMaleAthlete(athlt)} selectedAthlete={maleAthlete} />
          <AthleteInformation athlete={maleAthlete} cls={cls} gender="men" onSelectedAthlete={(athlete, gender) => handleOnSelectedAthlete(athlete, gender)} selectedAthlete={maleAthlete} />
        </Grid>
      );
    }

    return (
      <p>No Classes chosen</p>
    );
  };
  return (
    <Grid container spacing={2}>
      {renderAthleteAutoComplete(cls)}
    </Grid>
  );
}

export default AddAthlete;
