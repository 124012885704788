/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import Map from './components/Map';
import { PageContainer, GridItem } from '../../components/Shared/Grid/GridContainer';
import getMockGames from './components/data/mockGames';
import PracticeGroupCard from './components/PracticeGroupCard';
import styles from './PracticeGroups.css';
import config from '../../config';
import StateSelector from './components/StateSelector';
import ImageUploader from '../../components/PracticeGroups/ImageUploader';

const inputStyles = {
  margin: '10px',
  width: '90%',
  '@media(min-width: 768px)': {
    width: '100%',
  },
};

function PracticeGroups() {
  const [currentState, setCurrentState] = useState(false);
  const [practiceData, setpracticeData] = useState(false);
  const [isAddingNewGroup, setIsAddingNewGroup] = useState(false);
  const [currentPracticeGroups, setCurrentPracticeGroups] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [groupAddress, setGroupAddress] = useState('');
  const [groupEmail, setGroupEmail] = useState('');
  const [groupImage, setGroupImage] = useState('');
  const editorRef = useRef(null);

  useEffect(() => {
    async function fetchMyAPI() {
      let response = await fetch('https://api.hgscores.com/practice-groups/list');
      response = await response.json();
      setpracticeData(response);
    }

    fetchMyAPI();
  }, []);

  useEffect(() => {
    if (practiceData) {
      const practiceGroups = practiceData.filter((group) => group.state === currentState);
      setCurrentPracticeGroups(practiceGroups);
    }

    // const urlString = window.location.href;
    // const url = new URL(urlString);
    // const c = url.searchParams.get('state');
    // if (c) {
    //   setCurrentState(c);
    // }
  }, [currentState]);

  const handleStateClick = (state) => {
    setIsAddingNewGroup(false);
    setCurrentState(state);
    setGroupImage(false);
    window.history.replaceState(null, null, `?state=${state}`);
  };

  const handlePracticeAdd = () => {};

  const renderStatesData = () => {
    if (currentState && practiceData?.length === undefined) {
      return (
        <p>There are currently <strong>0</strong> practice groups in <strong>{currentState}</strong>. Be the first to add yours!</p>
      );
    }
    if (currentState && practiceData?.length > 0) {
      return (
        <>
          <p>There are currently <strong>{currentPracticeGroups?.length}</strong> practice groups in <strong>{currentState}.</strong></p>
        </>
      );
    }
    return false;
  };

  const renderPracticeGroups = () => currentPracticeGroups?.map((currentPracticeGroup, index) => (
    <GridItem sm={4} xs={10}>
      <PracticeGroupCard name={currentPracticeGroup.name} address={currentPracticeGroup.address} contact={currentPracticeGroup.contactEmail} image={currentPracticeGroup.image} description={currentPracticeGroup.description} link={currentPracticeGroup.link} onEditClick={() => console.log('clicked')} />
    </GridItem>
  ));

  const renderPracticeAddForm = () => (
    <>
      <TextField id="outlined-basic" label="Group Name" variant="outlined" className="practice-group-add-input" sx={inputStyles} onClick={(e) => setGroupName(e.target.value)} />

      <TextField id="outlined-basic" label="Group Contact Email" variant="outlined" className="practice-group-add-input" sx={inputStyles} onClick={(e) => setGroupEmail(e.target.value)} />

      <TextField id="outlined-basic" label="Group Contact Address" variant="outlined" onClick={(e) => setGroupAddress(e.target.value)} className="practice-group-add-input" sx={inputStyles} />

      <TextField id="outlined-basic" multiline rows={4} label="Group Contact Description" variant="outlined" className="practice-group-add-input" sx={inputStyles} />

      <ImageUploader groupImage={groupImage} setGroupImage={setGroupImage} />

      <Button variant="contained" type="button" sx={{ marginLeft: '10px' }} onClick={handlePracticeAdd}>Add Practice Group</Button>

    </>
  );

  return (
    <div className="main-container">
      <PageContainer>
        <GridItem sm={5}>
          <StateSelector handleStateSelection={(state) => handleStateClick(state)} />
          <Map handleStateSelection={(state) => handleStateClick(state)} />
        </GridItem>
        <GridItem sm={3}>
          {currentState && (
            <><h1>{currentState}</h1>
              <p><Button type="button" onClick={() => setIsAddingNewGroup(!isAddingNewGroup)}> { !isAddingNewGroup && ('+')}{ isAddingNewGroup && ('-')} Add your practice group</Button></p>
              {isAddingNewGroup && (
                <>
                  {renderPracticeAddForm()}
                </>
              )}
              {renderStatesData()}
            </>
          )}
          {!currentState && (
            <>
              <h1>Highland Games Practice Browser</h1>
              <p>Select a state from the map on the left (international groups coming soon). You'll see a list of practice groups in your state, and can even add your own!</p>
              <p>When adding your practice group, you will be asked to enter a password. This password will be used to edit or delete your group from this list.</p>
              <p>If you have issues adding your group, please reach out to support@hgscores.com</p>
              <p>There are currently <strong>{practiceData?.length}</strong> practice groups in total.</p>
            </>
          )}
        </GridItem>
        {renderPracticeGroups()}
      </PageContainer>
    </div>
  );
}
export default PracticeGroups;
