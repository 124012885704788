const toolTipData = {
  A: {
    Mens: {
      LWFD: '60 feet',
      HWFD: '30 feet',
      Hammer: '100 feet',
      WOB: '16 feet',
      Sheaf: '28 feet',
      Caber: '17ft 120lbs',
    },
    Womens: {
      LWFD: '40 feet',
      HWFD: '20 feet',
      Hammer: '70 feet',
      WOB: '12 feet',
      Sheaf: '20 feet',
      Caber: '13ft 80lbs',
    },
  },

  B: {
    Mens: {
      LWFD: '60 feet',
      HWFD: '30 feet',
      Hammer: '100 feet',
      WOB: '16 feet',
      Sheaf: '28 feet',
      Caber: '17ft 120lbs',
    },
    Womens: {
      LWFD: '40 feet',
      HWFD: '20 feet',
      Hammer: '70 feet',
      WOB: '12 feet',
      Sheaf: '20 feet',
      Caber: '13ft 80lbs',
    },
  },
  C: {
    Mens: {
      LWFD: '50 feet',
      HWFD: '25 feet',
      Hammer: '90 feet',
      WOB: '14 feet',
      Sheaf: '25 feet',
      Caber: '15ft 100lbs',
    },
    Womens: {
      LWFD: '30 feet',
      HWFD: '15 feet',
      Hammer: '60 feet',
      WOB: '10 feet',
      Sheaf: '15 feet',
      Caber: '11ft 60lbs',
    },
  },

  Novice: {
    Mens: {
      LWFD: '40 feet',
      HWFD: '20 feet',
      Hammer: '80 feet',
      WOB: '12 feet',
      Sheaf: '20 feet',
      Caber: '13ft 80lbs',
    },
    Womens: {
      LWFD: '25 feet',
      HWFD: '10 feet',
      Hammer: '50 feet',
      WOB: '8 feet',
      Sheaf: '10 feet',
      Caber: '9ft 40lbs',
    },
  },

  LightWeight: {
    Mens: {
      LWFD: '50 feet',
      HWFD: '25 feet',
      Hammer: '90 feet',
      WOB: '14 feet',
      Sheaf: '25 feet',
      Caber: '15ft 100lbs',
    },
    Womens: {
      LWFD: '30 feet',
      HWFD: '15 feet',
      Hammer: '60 feet',
      WOB: '10 feet',
      Sheaf: '15 feet',
      Caber: '11ft 60lbs',
    },
  },

  Pro: {
    Mens: {
      LWFD: '70 feet',
      HWFD: '35 feet',
      Hammer: '120 feet',
      WOB: '18 feet',
      Sheaf: '30 feet',
      Caber: '19ft 140lbs',
    },
    Womens: {
      LWFD: '50 feet',
      HWFD: '25 feet',
      Hammer: '90 feet',
      WOB: '14 feet',
      Sheaf: '25 feet',
      Caber: '15ft 100lbs',
    },
  },

  Masters: {
    Mens: {
      LWFD: '60 feet',
      HWFD: '30 feet',
      Hammer: '100 feet',
      WOB: '16 feet',
      Sheaf: '28 feet',
      Caber: '17ft 120lbs',
    },
    Womens: {
      LWFD: '40 feet',
      HWFD: '20 feet',
      Hammer: '70 feet',
      WOB: '12 feet',
      Sheaf: '20 feet',
      Caber: '13ft 80lbs',
    },
  },

  'Masters 50': {
    Mens: {
      LWFD: '50 feet',
      HWFD: '25 feet',
      Hammer: '90 feet',
      WOB: '14 feet',
      Sheaf: '25 feet',
      Caber: '15ft 100lbs',
    },
    Womens: {
      LWFD: '30 feet',
      HWFD: '15 feet',
      Hammer: '60 feet',
      WOB: '10 feet',
      Sheaf: '15 feet',
      Caber: '11ft 60lbs',
    },
  },

  'Masters 60': {
    Mens: {
      LWFD: '40 feet',
      HWFD: '20 feet',
      Hammer: '80 feet',
      WOB: '12 feet',
      Sheaf: '20 feet',
      Caber: '13ft 80lbs',
    },
    Womens: {
      LWFD: '25 feet',
      HWFD: '10 feet',
      Hammer: '50 feet',
      WOB: '8 feet',
      Sheaf: '10 feet',
      Caber: '9ft 40lbs',
    },
  },
  Open: {
    Mens: {
      LWFD: '70 feet',
      HWFD: '35 feet',
      Hammer: '120 feet',
      WOB: '18 feet',
      Sheaf: '30 feet',
      Caber: '19ft 140lbs',
    },
    Womens: {
      LWFD: '50 feet',
      HWFD: '25 feet',
      Hammer: '90 feet',
      WOB: '14 feet',
      Sheaf: '25 feet',
      Caber: '15ft 100lbs',
    },
  },

  'World Champion': {
    Mens: {
      LWFD: '90 feet',
      HWFD: '45 feet',
      Hammer: '140 feet',
      WOB: '22 feet',
      Sheaf: '40 feet',
      Caber: '23ft 180lbs',
    },
    Womens: {
      LWFD: '70 feet',
      HWFD: '35 feet',
      Hammer: '120 feet',
      WOB: '18 feet',
      Sheaf: '35 feet',
      Caber: '19ft 140lbs',
    },
  },
};
export default toolTipData;
