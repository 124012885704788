import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import { getBackgroundTartan } from '../utils/getRandomHsl';
import PracitceModal from './PracticeModal';
import styles from './PracticeGroupCard.css';

export default function PracticeGroupCard(props) {
  const {
    address, contact, name, image, description, link,
  } = props;
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);

  // Format name for auto image srcs
  const formattedName = name.replaceAll('-', ' ');
  const imgUrl = `https://api.hgscores.com/images/practice-groups/${formattedName.replace(/\s+|-|--/g, '-').toLowerCase()}.png`;

  const backgroundStyles = getBackgroundTartan();

  const handleEditPracticegroup = () => {};
  const handleDeletePraticeGroup = () => {};

  const handleModalClick = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  return (
    <>
      <PracitceModal isOpen={isEditModalOpen} onClose={handleModalClick} name={name} address={address} description={description} contact={contact} link={link} image={image} instagram={' '} />
      <Card>
        <CardHeader
        // avatar={(
        //   <Avatar sx={{ bgcolor: '#3e4a94', fontSize: '1em' }} aria-label="recipe">
        //     HG
        //   </Avatar>
        // )}
          action={(
            <IconButton aria-label="settings" sx={{ fontSize: '.8em' }}>
              <EditIcon onClick={handleModalClick} />
            </IconButton>
        )}
          className="group-name"
          classes={{
            title: 'group-name',
          }}
          title={name}
        />
        {
        image && (
          <CardContent sx={{ height: '185px', ...backgroundStyles }}>
            <img src={imgUrl} alt="pratice group" className="practice-group-image" />
          </CardContent>
        )
      }

        {
        !image && (
          <div className="no-image" style={backgroundStyles} />
        )
      }

        <CardActions disableSpacing />

        <CardContent>
          <div className="practice-card-contents">
            {
                description && (
                  <Typography paragraph>{description}</Typography>
                )
              }
            {
                  link && (
                    <Typography paragraph><strong>Website: <a href={link} target="_blank" rel="noreferrer">{link}</a></strong></Typography>
                  )
              }
            {
                  contact && (
                    <Typography paragraph><strong>Contact: <a href={link} target="_blank" rel="noreferrer">{contact}</a></strong></Typography>
                  )
              }
          </div>
        </CardContent>

      </Card>
    </>
  );
}
