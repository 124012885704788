import React from 'react';
import Box from '@mui/material/Box';
import DevicesIcon from '@mui/icons-material/Devices';
import styled from '@emotion/styled';
import GridContainer, { GridItem } from '../components/Shared/Grid/GridContainer';
import familyImage from '../shared/images/campaign-family-2.png';
import linkedInImage from '../shared/images/linkedin.jpg';
import instagramImage from '../shared/images/instagram.png';
import CampaignEmail from './components/CampaignEmail';
import './styles/campaign.css';

const StyledBox = styled(Box)(() => ({
  height: 'auto',
  width: '100%',
  maxWidth: '520px',
  border: '2px solid #004b84',
  '@media (max-width: 736px)': {
    width: '100%',
  },
  '@media (max-width: 450px)': {
    width: '100%',
  },
  '@media all and (device-width: 768px) and (device-height: 1024px)': {
    width: '100%',
  },
}));

const MEDIA_QUERY_CSS = {
  width: '100%',
  '@media (max-width: 700px)': {
    'margin-left': '20px',
    'margin-right': '20px',
  },
};

function Campaign() {
  return (
    <div className="campaign-container">
      <GridContainer>
        <GridItem xs={12} sm={12} smOffset={0}>
          <p style={{ textAlign: 'center', textDecoration: 'underline', marginTop: '0px' }} className="tartan primary">Francis Cullen for SAAA Board of Directors 2023
          </p>
        </GridItem>

        <GridItem xs={12} sm={2.9} xsOffset={1} smOffset={1.5} sx={MEDIA_QUERY_CSS}>
          <StyledBox
            className="family-picture"
            component="img"
            alt="The Cullen Family"
            src={familyImage}
          />
        </GridItem>
        <GridItem
          xs={12}
          sm={6}
          xsOffset={0}
          smOffset={1}
          sx={MEDIA_QUERY_CSS}
        >

          <h1>My goals</h1>
          <p>My name is <span className="title">Francis Cullen</span>. I am a member and athlete with the Scottish American Athletic Association (<span className="title">SAAA</span>). I'm running for a position on the Board of Directors of the <span className="title">SAAA</span>, and I'd like to tell you a little bit about my self and my intentions with this campaign. Below are some of my goals.</p>
          <ul className="goals-list">
            <li>
              <span className="title">
                <a href="#goal-website">New Website</a>
              </span>
            </li>

            <li>
              <span className="title">
                <a href="#goal-sponsors">Increased Sponsorship</a>
              </span>
            </li>

            <li>
              <span className="title">
                <a href="#goal-board">Increase Board of Directors Accessibility and Transparency</a>
              </span>
            </li>

            <li>
              <span className="title">
                <a href="#goal-profits">Increase Profitability</a>
              </span>
            </li>
          </ul>

        </GridItem>

        <GridItem xs={12} sm={7} smOffset={1} order={{ xs: 1, sm: 2 }} sx={MEDIA_QUERY_CSS}>
          <p className="tartan primary">About Me</p>

          <p>I am a career Software Engineer and Architect. I write, design, and manage applications that service millions of users for Intuit every day. In my free time, I like to play with my son <span className="title">Riker</span>, playing video games with my wife <span className="title">Sara</span>, veg out to a really great tv show or movie, or work on side projects to perfect my craft. In fact two of my current side projects are:</p>

          <ul className="feature-list">
            <li>
              <p>A feature rich scoring software solution for our sport.</p>
            </li>
            <li>
              <p>A highland games database API. This would open the Highland Games data up to innovation by others who would like to contribute.</p>
            </li>
          </ul>

          <p>My wife and I are both athletes, and big fans of our sport. Because this is important to us, the organization that empowers our ability to throw, and put on a good show at our Games is has become very important to us as well. </p>

          <p>I'd like to become a member of the <strong>Board Of Directors</strong> for the <span className="title">SAAA</span>. I'd like to stay involved in the organization, and help drive improvement, and better managment. I'd like to inject some passion, and energy into creating the best possible version of our organization. I think the board currently has done a great job, and I'm looking forward to hopefully improving on that.</p>

          <GridContainer>
            <GridItem xs={12} sm={12} smOffset={0} sx={MEDIA_QUERY_CSS}>
              <p className="tartan primary">My Plan</p>
              <ol>
                <li><span className="title" id="goal-website">New Website</span>
                  <p>I believe with my years as a software engineer, I have an opportunity to improve our organizations power and presence with new tools and exciting online features. I'd like to create a new website for the <span className="title">SAAA</span>, that becomes the central hub for all of our members. Some of the features are:</p>

                  <ul>
                    <li>
                      <p>Each athlete has a profile page, with their stats, and a list of their accomplishments, PRs, and awards</p>
                    </li>
                    <li>
                      <p>A discussion forum for athletes to share information, and connect.</p>
                    </li>
                    <li>
                      <p><span className="title">SAAA</span> events are registered for on the <span className="title">SAAA</span> website</p>
                    </li>
                    <li>
                      <p>A members only area where members can</p>
                      <ul>
                        <li>
                          <p>Update and pay for their membership</p>
                        </li>
                        <li>
                          <p>Update their athlete profile information</p>
                        </li>
                        <li>
                          <p>Review board meeting minutes</p>
                        </li>
                        <li>
                          <p>Vote on and see the results of board initiatives</p>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li><span className="title" id="goal-sponsors">Increased Sponsorship</span>
                  <p>I aim to expand our sport's sponsor list by reaching out to local and larger companies to showcase our dynamic and female-friendly sport. Building mutually beneficial relationships with sponsors can lead to financial support, equipment donations, food for athletes, and even venue and practice grounds. I am determined to explore every reasonable avenue to generate greater sponsorships and have already made progress in this area. Once on the board, I will represent our organization and work with supporters to further our goals.</p>
                </li>

                <li><span className="title" id="goal-board">Increase Board of Directors Accessibility and Transparency</span>
                  <p>I'd like to help bridge any gaps between athletes and the board. Our members contribute to make our organization what it is, and I'd like everyone to be as involved as they'd like.</p>

                  <ul>
                    <li><p>Easy forms of contact online</p></li>
                    <li><p>Board minutes made accessible to membership</p></li>
                    <li><p>SAAA Newsletter</p></li>
                  </ul>
                </li>

                <li><span className="title" id="goal-profits">Increase Profitability</span>

                  <p>Although the <span className="title">SAAA</span> is a non-profit organization, they can still generate profit to benefit the organization. To do so, I suggest creating an online store on the <span className="title">SAAA</span> website that sells merchandise such as t-shirts, stickers, water bottles, and equipment. This would provide athletes with desired items while supporting the organization. Furthermore, by managing event registration in-house, we can save money currently paid to EventBrite for their services.</p>
                </li>
              </ol>

            </GridItem>

            <GridItem xs={12} sm={12} smOffset={0}>
              <p className="tartan primary">Next Steps</p>
              <p>I'd love to talk about any of this with any who is interested. Follow me on Instagram, and I'll post how you can help support my cause by providing your vote for me when the time comes. I'd also love to hear more ideas, comments and critiques from our athletes and spectators.</p>

              <p>If you'd like to reach out, want to tell me you're gonna vote for me, or just say hi, reach out on social media, or use the form below to email me.</p>
              <CampaignEmail />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={3.4} smOffset={0} sx={MEDIA_QUERY_CSS}>
          <p className="tartan primary">Contact</p>
          <p style={{ textAlign: 'center' }}>
            <strong>Email:</strong><br /> franciscullen.sd@gmail.com
          </p>
          <p style={{ textAlign: 'center' }}>
            <strong>Telegram:<br /></strong> 858-360-0300
          </p>
          <p style={{ textAlign: 'center' }}>
            <a href="https://www.linkedin.com/in/franciscullen/" target="_blank" rel="noreferrer">
              <img src={linkedInImage} alt="" className="linked-in-image" />
            </a>
          </p>
          <p style={{ textAlign: 'center' }}>
            <a href="https://www.instagram.com/0x29a_/" target="_blank" rel="noreferrer">
              <img src={instagramImage} alt="" className="instagram-image" />
            </a>
          </p>
        </GridItem>
      </GridContainer>
    </div>
  );
}
export default Campaign;
