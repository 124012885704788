import React from 'react';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import styles from './styles/AthleteInformation.css';
import GridContainer, { GridItem } from '../Shared/Grid/GridContainer';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function AthleteInformation(props) {
  const {
    athlete = false,
    gender = '',
    onSelectedAthlete,
  } = props || {};

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log('athlete', athlete);

  return (
    <div className="athlete-information">
      {
        athlete && (
          <GridContainer>
            <GridItem smOffset={0} sm={8}>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Stats" {...a11yProps(0)} />
                    <Tab label="Recent Scores" {...a11yProps(1)} />
                    {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <List component="nav" aria-label="mailbox folders">
                    <ListItem button>
                      <ListItemText secondary="Full Name" primary={`${athlete.firstName} ${athlete.lastName}`} />
                    </ListItem>
                    <Divider />
                    <ListItem button divider>
                      <ListItemText secondary="Email" primary={`${athlete.email}`} />
                    </ListItem>
                    <ListItem button>
                      <ListItemText secondary="T-Shirt Size" primary={`${athlete.tshirtSize}`} />
                    </ListItem>
                    <Divider light />
                    <ListItem button>
                      <ListItemText secondary="Home State" primary={`${athlete.state}`} />
                    </ListItem>
                  </List>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  Item Two
                </TabPanel>
                {/* <TabPanel value={value} index={2}>
                  Item Three
                </TabPanel> */}
              </Box>
            </GridItem>
            <GridItem smOffset={0} sm={3}>
              <Button fullWidth variant="contained" onClick={() => onSelectedAthlete(athlete, gender)}>Add {athlete?.firstName} {athlete?.lastName} </Button>
              {/* <img src={athlete?.imgUrl} alt="foo" className="athlete-image" /> */}
            </GridItem>
          </GridContainer>
        )
      }
      {
        !athlete && (
          <GridContainer>
            <GridItem smOffset={0} sm={8} />
            <GridItem smOffset={0} sm={3}>
              <Button fullWidth variant="contained">Add New Athlete</Button>
            </GridItem>
          </GridContainer>
        )
      }
    </div>
  );
}

export default AthleteInformation;
