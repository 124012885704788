/* eslint-disable react/prop-types */
import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';

function GridContainer(props) {
  const {
    spacing = 2, children = false, item = false, sx = {},
  } = props;
  return (
    <Grid container item={item} spacing={spacing} sx={sx}>
      {children}
    </Grid>
  );
}

export function PageContainer(props) {
  const {
    spacing = 2, children = false, item = false, sx = {},
  } = props;
  return (
    <GridContainer {...props}>
      {children}
    </GridContainer>
  );
}

export function GridItem(props) {
  const {
    xs = 12, sm = 6, smOffset = 1, xsOffset = 1, children = false, sx = {}, order = false, className = false,
  } = props;
  return (
    <Grid item xs={xs} sm={sm} smOffset={smOffset} xsOffset={xsOffset} sx={sx} className={className}>
      {children}
    </Grid>
  );
}
export default GridContainer;
