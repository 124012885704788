/* eslint-disable import/no-extraneous-dependencies */
import './App.css';
import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import Root from './routes/root';
import ErrorPage from './error';
import AddGame from './pages/AddGame';
import About from './pages/About';
import Home from './pages/Home';
import GamesList from './pages/GamesList';
import Athletes from './pages/Athletes';
import InDev from './pages/InDev';
import Contact from './pages/Contact';
import config from './config';
import Campaign from './pages/Campaign';
import PracticeGroups from './pages/practice_groups/PracticeGroups';

const getRouter = () => ((config.isInDev) ? createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <InDev />,
      },
      {
        path: 'contact',
        element: <Contact />,
      },
      {
        path: 'about',
        element: <About />,
      },
      {
        path: 'campaign',
        element: <Campaign />,
      },
      {
        path: 'practice',
        element: <PracticeGroups />,
      },
    ],
  },
]) : createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: 'add-game',
        element: <AddGame />,
      },
      {
        path: 'about',
        element: <About />,
      },
      {
        path: 'athletes',
        element: <Athletes />,
      },
      {
        path: 'games-list',
        element: <GamesList />,
      },
      {
        path: 'games-list/judges/:judge',
        element: <GamesList />,
      },
      {
        path: 'games-list/athletes/:athlete',
        element: <GamesList />,
      },
      {
        path: 'games-list/name/:name',
        element: <GamesList />,
      },
      {
        path: 'games-list/athleticDirector/:athleticDirector',
        element: <GamesList />,
      },
      {
        path: 'games-list/search/:search',
        element: <GamesList />,
      },
    ],
  },
]));

function App() {
  return (
    <div>
      <RouterProvider router={getRouter()} />
    </div>
  );
}

export default App;
