/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { styled } from '@mui/system';

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: '#FFF',
  backgroundColor: '#004b84',
}));

const GroupItems = styled('ul')({
  padding: 0,
});

export default function AthleteAutoComplete(props) {
  const {
    athletes, cls, setAthlete, selectedAthlete,
  } = props;
  const options = athletes.map((option) => {
    const firstLetter = option.firstName[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  return (
    <Autocomplete
      freeSolo
      id="athletes-autocomplete"
      options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
      groupBy={(option) => option.firstLetter}
      getOptionLabel={(option) => `${option.firstName} ${option.lastName}  (${option.email})`}
      getOptionSelected={(option) => option.firstName}
      onChange={(event, value) => setAthlete(value)}
      sx={{ width: '90%', display: 'inline-block' }}
      renderInput={(params) => <TextField {...params} label={`Search ${cls} Class Athletes`} />}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>
            {params.children}
          </GroupItems>
        </li>
      )}
    />
  );
}
