import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import InfoTile from './InfoTile';
import img1 from '../../shared/images/scott1.png';
import img2 from '../../shared/images/scott2.png';
import img3 from '../../shared/images/scott3.png';
import img4 from '../../shared/images/scott4.png';
import img5 from '../../shared/images/scott5.png';
import img6 from '../../shared/images/scott6.png';

const images = [
  {
    img: img1,
    text:
  <p>
    Here you will enter basic information about your games. You'll enter information such as the
    {' '}
    <strong>game name</strong>
    ,
    {' '}
    <strong>sddress</strong>
    , and
    {' '}
    <strong>dates</strong>
    {' '}
    of the event.
  </p>,
    title: 'Game Details',
  },
  {
    img: img2,
    text:
  <p>
    Additional information about your games can be added here. Here you'll define things like the
    {' '}
    <strong>athletic director, score keeper</strong>
    {' '}
    and
    {' '}
    <strong>judges</strong>
    , and other officials. In addition
    {' '}
    <strong>media</strong>
    {' '}
    and event links can be added here.
  </p>,
    title: 'Advanced',
  },
  {
    img: img3,
    text:
  <p>
    Here you will define the classes for your games, for both
    {' '}
    <strong>Men</strong>
    , and
    {' '}
    <strong>Women</strong>
  </p>,
    title: 'Classes',
  },
  {
    img: img4,
    text:
  <p>
    You can define the events for your games here. A core list will be provided based on the event type. You can add additional events as needed, disable, and restore from defaults.
  </p>,
    title: 'Events',
  },
  {
    img: img5,
    text:
  <p>
    Once classes and events are determined, it's time to add athletes. You can add athletes individually, or in bulk. You can also import athletes from a previous game.
  </p>,
    title: 'Athletes',
  },
  {
    img: img6,
    text:
  <p>
    Here you can confirm all of the details about your games. You'll get a report on the events, atheletes, classes and more. If something is misconfigured (.I.E.: A class has no athletes), you'll be asked to confirm. Once you're ready, you can submit your game. Once your game is submitted, you'll be able to edit it at any time, and update the event scores.
  </p>,
    title: 'Submission',
  },
];

function Info() {
  return (
    <Grid container spacing={2} smOffset={1}>
      <Grid item xs={12} sm={6} smOffset={2}>
        <h2>Adding a Game to HG Score</h2>
        <p>Adding a game to HG Score is easy. We've implemented various features to make this as fast, and easy as possible. More information about each section can be found below. If this is your first time score keeping, familiarizing your self once with this section is likely all you'll need. </p>
      </Grid>
      <Grid xs={3} />
      {
          images.map((image, i) => (
            <Grid xs={12} smOffset={0} sm={3}>
              <InfoTile imgSrc={image.img} text={image.text} title={image.title || 'foo'} />
            </Grid>
          ))
        }
    </Grid>
  );
}
export default Info;
