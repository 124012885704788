/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React from 'react';
import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import GridContainer, { GridItem } from '../Shared/Grid/GridContainer';
import styles from './GameDetails.css';

function GameDetails(props) {
  const {
    game: {
      name = '',
      address = '',
      scoreKeeper = '',
      id = '',
      date = '',
      athleticDirector = '',
      athletes = [],
      events = [],
      classes = [],
    },
  } = props;
  console.log('props', props);
  return (
    <GridContainer>
      <GridItem smOffset={0} sm={3}>
        <Box>
          {/* <p className="headline">The following information is what we have saved for this event. If you're logged in, and would like to edit these details you can.</p> */}
          <p>
            <span className="detail">Games Name:</span> {name}
          </p>
          <p>
            <span className="detail">Date:</span> {date}
          </p>
          <p>
            <span className="detail">Score Keeper:</span> {scoreKeeper}
          </p>
          <p>
            <span className="detail">Athletic Director:</span> {athleticDirector}
          </p>
          <p>
            <span className="detail">Address:</span> {address}
          </p>

          <img className="games-map" src={`https://maps.googleapis.com/maps/api/staticmap?center=${address}&zoom=13&scale=2&size=600x300&maptype=roadmap&format=png&key=AIzaSyDyNMz0uFzH3E4eTmXf6lbkdLWkNZVyhrQ`} alt={`Google map of ${address}`} />

        </Box>
      </GridItem>

      <GridItem smOffset={1} sm={3}>
        <p className="headline center strong">Events ({events.length})</p>
        <GridContainer>

          {
              events.map((event) => (
                <GridItem smOffset={0} xs={12} sm={6}>
                  <Card className="event-card">
                    <CardContent>
                      {event}
                    </CardContent>
                  </Card>
                </GridItem>
              ))
            }

        </GridContainer>
      </GridItem>

      <GridItem smOffset={2} sm={3}>
        <p className="headline center strong">Athletes (72)</p>
        <GridContainer>
          <GridItem smOffset={0} sm={6}>
            <p className="center strong">Men (50)</p>
            <p><a href="/athletes/:athlete">Francis Cullen - A</a></p>
          </GridItem>

          <GridItem smOffset={0} sm={6}>
            <Box className="bounding-box">
              <p className="center strong">Women (22)</p>
              <p><a href="/athletes/:athlete">Sara Cullen - A</a></p>
            </Box>
          </GridItem>
        </GridContainer>
      </GridItem>

    </GridContainer>
  );
}
export default GameDetails;
