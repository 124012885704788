import React from 'react';
import GridContainer, { GridItem } from '../components/Shared/Grid/GridContainer';
import Games from '../components/GamesList/Games';

function GamesList() {
  return (
    <GridContainer>
      <GridItem>
        <h1>Games</h1>
      </GridItem>
      <GridItem smOffset={1} sm={10}>
        <Games />
      </GridItem>
    </GridContainer>
  );
}
export default GamesList;
