import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function Home() {
  return (
    <>
      <Grid container>
        <Grid xs={12} sm={6} smOffset={3}>
          <h1>HG Score</h1>
          <p>
            HGScores.com is the next generation of Scottish Highland games score keeping. As the old guard of the field grow older, more dated and brittle, the sport loses its ability to grow, expand and evolve. The scoring software provided by HGScores.com is free, modern, and extensible. Below are some of our basic features. Works in real-time, or can work off-line.
          </p>

          <p>
            In partnership with CaberMetrics.com, we bring you. HGScore.
          </p>
        </Grid>
      </Grid>

      <Grid container>

        <Grid xs={12} sm={6} smOffset={3}>
          <p style={{ 'text-align': 'center', 'font-weight': 'bold' }}>Features</p>
          <p><strong>Progress</strong></p>
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" value="25" />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="caption" component="div" color="text.secondary">
                25%
              </Typography>
            </Box>
          </Box>
          <ul>
            <li>Multi-Cast integration for score keeping (NASGA, CaberMetrics, ScottishScores)</li>
            <li>Offline backs. Backup any game or athlete datasheet to an excel document</li>
            <li>Real-time scoring. Watch scores and events in real time</li>
            <li>Built-in marketing. Broadcast your game, or promote an athlete (or class of athletes) on IG, FB, etc..</li>
            <li>Creating and editing games and athletes is easy with a modern, fast ui, mobile friendly UI.</li>
            <li>Athlete auto complete.</li>
            <li>Judges can score from the field in real-time.</li>
            <li>Communicate with your athletes. Create a game within HGScore. Once completed, at the click of a button email all atheletes in all classes with a custom message, and information.</li>
          </ul>
        </Grid>
      </Grid>
    </>
  );
}
export default Home;
