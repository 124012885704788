import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import config from '../../config';

function ContactForm() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [mailSuccess, setMailSuccess] = useState(false);

  const handleEmail = () => {
    const request = (url, params = {}, method = 'POST') => {
      const options = {
        method,
      };
      if (method === 'GET') {
        url += `?${(new URLSearchParams(params)).toString()}`;
      } else {
        options.body = JSON.stringify(params);
      }

      return fetch(url, options).then((response) => response.json());
    };
    const post = (url, params) => request(url, params, 'POST');
    post(config.endpoints.devContactEmail, { email, message }).then((response) => {
      console.log('response', JSON.stringify(response));
      if (response.success === true) {
        setMailSuccess(true);
      }
    });
  };

  return (
    <>
      <h1>Pre Release Contact</h1>
      <p>We're looking for beta testers, feature ideas, imrpovements, critiques and more! We want to make the best scoring software for Highland Games competitions! If you have any ideas, please feel free to contact us.</p>
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '95%' },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField id="outlined-basic" label="Email Address" variant="outlined" onChange={(e) => setEmail(e.target.value)} />
        <TextField
          fullWidth
          id="standard-basic"
          label="Email Message"
          variant="outlined"
          multiline
          sx={{ display: 'block' }}
          rows={4}
          onChange={(e) => setMessage(e.target.value)}
        />
      </Box>
      <Button fullWidth variant="contained" sx={{ background: '#004b84', width: '50%' }} onClick={() => handleEmail()}>Send Email</Button>
      {
        mailSuccess && (
        <p>
          Your email was successfully delivered! We'll contact you back at:
          {' '}
          <strong>{email}</strong>
          . Please feel free to send more emails if needed.
        </p>
        )
      }
    </>
  );
}
export default ContactForm;
