const events = {
  'Highland Games': [
    {
      name: 'Light Weight For Disance',
      abbrv: 'LWFD',
      averageScores: '',
      description: 'Athletes throw a 21-28lb metal implement attached to a chain and handle. The athlete must throw the orb as far as they can. Athletes are scored on distance.',
      enabled: true,
    },
    {
      name: 'Heavy Weight For Distance',
      abbrv: 'HWFD',
      averageScores: '',
      description: 'Athletes throw a 42-56lb metal implement attached to a chain and handle. The athlete must throw the orb as far as they can. Athletes are scored on distance.',
      enabled: true,
    },
    {
      name: 'Heavy Hammer',
      abbrv: 'HHammer',
      averageScores: '',
      description: 'Athletes throw a 14-15lb metal orbed \'hammer\'. The athlete must throw the hammer as far as they can. Athletes are scored on distance.',
      enabled: true,
    },
    {
      name: 'Light Hammer',
      abbrv: 'LHammer',
      averageScores: '',
      description: 'Athletes throw a 16-22lb metal orbed \'hammer\'. The athlete must throw the hammer as far as they can. Athletes are scored on distance.',
      enabled: true,
    },
    {
      name: 'Weight Over Bar',
      abbrv: 'WOB',
      averageScores: '',
      description: 'Athletes attempt toss over a horizontal bar a heavy weight. The weight depending on class is 42-56lbs. The bar is raised in increments of 1-3 feet (depending on class and skill level) until only one athlete remains.',
      enabled: true,
    },
    {
      name: 'Sheaf',
      abbrv: 'Sheaf',
      averageScores: '',
      description: 'Athletes use a pitchfork to toss a burlap bag stuffed with straw over a horizontal bar. The bar is raised in increments of 1-3 feet (depending on class and skill level) feet until only one athlete remains.',
      enabled: true,
    },
    {
      name: 'Caber',
      abbrv: 'Caber',
      averageScores: '',
      description: 'Athletes demonstrate control, and stamina by tossing a 16-20 foot long tapered pole end over end. The caber is scored for accuracy and distance. The athlete must toss the caber so that it turns end over end and lands with the small end pointing directly away from the athlete. The athlete is scored for accuracy and distance. The caber is scored for accuracy and distance. The athlete must toss the caber so that it turns end over end and lands with the small end pointing directly away from the athlete. The athlete is scored for accuracy and distance.',
      enabled: true,
    },
  ],
};
export default events;
