/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import toolTipData from '../../data/games/highlandsAverages';
import './styles/ClassesTable.css';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#004b84',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function CombinedClassesTable(props) {
  const { chosenClasses, headline, sortDirection } = props;

  const getTooltip = (chosenClass, athlete) => (
    <div>
      <p className="tooltip-title">
        <strong>{athlete} - {chosenClass}</strong>
      </p>
      <p className="tooltip-text">
        <span className="tooltip-headline">Athletes in this category should strive to hit, roughly, the following marks:</span>
        <TableContainer component={Paper} className="tooltip-container">
          <Table>
            <TableBody>

              <StyledTableRow>
                <span className="left bold">LWFD:</span> <span className="right">{toolTipData?.[chosenClass]?.[athlete].LWFD}</span>
              </StyledTableRow>

              <StyledTableRow>
                <span className="left bold">HWFD:</span> <span className="right">{toolTipData?.[chosenClass]?.[athlete].HWFD}</span>
              </StyledTableRow>

              <StyledTableRow>
                <span className="left bold">Hammer:</span> <span className="right">{toolTipData?.[chosenClass]?.[athlete].Hammer}</span>
              </StyledTableRow>

              <StyledTableRow>
                <span className="left bold">WOB:</span> <span className="right">{toolTipData?.[chosenClass]?.[athlete].WOB}</span>
              </StyledTableRow>

              <StyledTableRow>
                <span className="left bold">Sheaf:</span> <span className="right">{toolTipData?.[chosenClass]?.[athlete].Sheaf}</span>
              </StyledTableRow>

              <StyledTableRow>
                <span className="left bold">Caber:</span> <span className="right">{toolTipData?.[chosenClass]?.[athlete].Caber}</span>
              </StyledTableRow>

            </TableBody>
          </Table>
        </TableContainer>
      </p>

    </div>
  );

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell align={sortDirection}>{headline}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chosenClasses?.map((chosenClass) => (
              <StyledTableRow key={chosenClass}>
                <StyledTableCell align={sortDirection} component="th" scope="row">
                  <Tooltip title={getTooltip(chosenClass, headline)} placement="top">
                    <span className="class-tooltip">{chosenClass}</span>
                  </Tooltip>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
