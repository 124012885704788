/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from '@amir04lm26/react-modern-calendar-date-picker';
import { useSelector, useDispatch } from 'react-redux';
import HelperIcon from '../HelperIcon/HelperIcon';
import GameDatesList from './GameDatesList';
import usStates from '../../data/usStates';
import './styles/GameDetails.css';
import {
  updateName,
  updateAddress1,
  updateAddress2,
  updateCity,
  updateCountry,
  updateDates,
  updateState,
  updateZip,
} from '../../slices/gamesDetailsSlice';

function GameDetails() {
  const countries = ['United States'];
  const dispatch = useDispatch();

  const gameName = useSelector((state) => state.gameDetails.name);
  const gameAddress1 = useSelector((state) => state.gameDetails.address1);
  const gameAddress2 = useSelector((state) => state.gameDetails.address2);
  const gameCity = useSelector((state) => state.gameDetails.city);
  const gameZip = useSelector((state) => state.gameDetails.zip);
  const gameCountry = useSelector((state) => state.gameDetails.country);
  const gameDates = useSelector((state) => state.gameDetails.dates);
  const gameState = useSelector((state) => state.gameDetails.state);
  const gameAddress = useSelector((state) => {
    if (!gameAddress1) {
      return state.gameDetails.address;
    }
    return `${state.gameDetails.address1}+${state.gameDetails.address2}+${state.gameDetails.state}+${state.gameDetails.zip}+${state.gameDetails.country}`;
  });

  const removeDate = (date) => {
    dispatch(updateDates(gameDates.filter((e) => e !== date)));
  };

  return (
    <p>
      <Grid container spacing={2}>
        <Grid xs={12} smOffset={0}>
          {/* <Paper elevation={1} className="games-banner paper-padding">
            <h2 style={{ textAlign: 'center' }}>Adding a new Game</h2>
            <p>
              Enter information about your games. This includes the name of the game, the date, the location, and the classes that will be competing. Use the tabs above to customize the classes and athletes. Finally, the submit tab will allow you to submit your game, and verify it's details. You can edit the game at any time.
            </p>
          </Paper> */}
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl>
            <Paper elevation={2} className="games-input paper-padding">
              <h3>Game Details</h3>
              <p>
                Fill in the fields below to provide the basic information for your games. This can be edited later at any time. There are more games related settings and features that can be found in the
                {' '}
                <i>advanced</i>
                {' '}
                tab.
              </p>
              <p>
                <TextField
                  placeholder=""
                  label="Game Name"
                  onChange={(e) => dispatch(updateName(e.target.value))}
                  value={gameName}
                  fullWidth
                  variant="outlined"
                  size="medium"
                  type="text"
                  name="title"
                  InputProps={{
                    endAdornment: (
                      <HelperIcon
                        title="Game Name"
                        description="This is the full name of your game.  It will be displayed on the game page, and in the game list."
                      />
                    ),
                  }}
                />
              </p>
              <Grid container>
                <Grid xs={12} sm={6}>
                  <b>Game Days</b>
                  <Calendar
                    value={gameDates}
                    onChange={(e) => dispatch(updateDates(e))}
                  />
                </Grid>
                <Grid xs={12} sm={6}>
                  <GameDatesList dates={gameDates} removeDate={(date) => removeDate(date)} />
                </Grid>
              </Grid>
            </Paper>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <Paper elevation={2} className="paper-padding form-margin">
            <p>
              <strong className="form-margin">Address</strong>
            </p>
            <Grid container>
              <Grid xs={12} sm={6}>
                <TextField
                  placeholder=""
                  label="Address Line 1"
                  variant="outlined"
                  onChange={(e) => dispatch(updateAddress1(e.target.value))}
                  value={gameAddress1}
                  fullWidth
                  size="medium"
                  type="text"
                  name="title"
                  InputProps={{
                    endAdornment: (
                      <HelperIcon
                        title="Primary Address"
                        description="Enter the primary address for the games location."
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <TextField
                  placeholder=""
                  label="Address Line 2"
                  onChange={(e) => dispatch(updateAddress2(e.target.value))}
                  value={gameAddress2}
                  variant="outlined"
                  fullWidth
                  size="medium"
                  type="text"
                  name="title"
                  InputProps={{
                    endAdornment: (
                      <HelperIcon
                        title="Additional Address Information"
                        description="If there is a suite, or additional address information, add it here."
                      />
                    ),
                  }}
                />
              </Grid>
            </Grid>

            {/**
               * Address City and State
               */}
            <Grid container>
              <Grid xs={12} sm={6} className="form-margin">
                <TextField
                  placeholder=""
                  label="City"
                  variant="outlined"
                  fullWidth
                  size="medium"
                  type="text"
                  name="title"
                  onChange={(e) => dispatch(updateCity(e.target.value))}
                  value={gameCity}
                  InputProps={{
                    endAdornment: (
                      <HelperIcon
                        title="Address City"
                        description="Enter the city for the games location."
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid xs={12} sm={6} className="form-margin">
                <Select
                  displayEmpty
                  fullWidth
                  size="medium"
                  placeholder="test"
                  value={gameState}
                  onChange={(e) => dispatch(updateState(e.target.value))}
                >
                  <MenuItem value="">State</MenuItem>
                  {usStates.map((state) => <MenuItem key={state.abbreviation} value={state.abbreviation}>{state.name}</MenuItem>)}
                </Select>
              </Grid>
            </Grid>

            {/**
               * State and Zipcode
               */}
            <Grid container>
              <Grid xs={12} sm={6} className="form-margin">
                <TextField
                  placeholder=""
                  label="Zipcode"
                  variant="outlined"
                  fullWidth
                  size="medium"
                  type="text"
                  name="title"
                  onChange={(e) => dispatch(updateZip(e.target.value))}
                  value={gameZip}
                  InputProps={{
                    endAdornment: (
                      <HelperIcon
                        title="Address Zipcode"
                        description="Enter the address Zipcode."
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid xs={12} sm={6} className="form-margin">
                <Select
                  displayEmpty
                  fullWidth
                  size="medium"
                  placeholder="test"
                  value={gameCountry}
                  onChange={(e) => dispatch(updateCountry(e.target.value))}
                >
                  {countries.map((country) => <MenuItem key={country} value={country}>{country}</MenuItem>)}
                </Select>
              </Grid>
            </Grid>

            {/**
               * MAP VIEW
               */}
            <Grid container>
              <Grid xs={12} sm={12} className="form-margin">
                <img className="games-map" src={`https://maps.googleapis.com/maps/api/staticmap?center=${gameAddress}&zoom=13&scale=2&size=600x300&maptype=roadmap&format=png&key=AIzaSyDyNMz0uFzH3E4eTmXf6lbkdLWkNZVyhrQ`} alt={`Google map of ${gameAddress}`} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </p>
  );
}
export default GameDetails;
