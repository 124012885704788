/* eslint-disable import/no-extraneous-dependencies */
import { configureStore } from '@reduxjs/toolkit';
import gameDetailsReducer from './slices/gamesDetailsSlice';

const store = configureStore({
  reducer: {
    gameDetails: gameDetailsReducer,
  },
});
export default store;
