import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import GridContainer, { GridItem } from '../components/Shared/Grid/GridContainer';

function About() {
  return (
    <GridContainer>
      <GridItem smOffset={1}>
        <h1>About HGScores</h1>
        <p>This software is a series of projects for the Highland Games community written by <a href="https://app.cabermetrics.com/database/athletes/francis-cullen" target="_blank" rel="noreferrer">Francis Cullen</a>.</p>
        <p>It is written predominately in Javascript.</p>
        <p>
          The source directory currently has these stats:
        </p>
        <p><strong>Scoring:</strong> 65 files, 3526 codes, 139 comments, 325 blanks, all 3990 lines</p>
        <p><strong>API:</strong> 27 files, 780 codes, 75 comments, 96 blanks, all 951 lines</p>
      </GridItem>
    </GridContainer>
  );
}
export default About;
