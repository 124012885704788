/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import GameDetails from '../GameDetails/GameDetails';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

/**
 * Game View
 * @returns {JSX.Element} Edit Game component
 */
function GameView(props) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {
    game = {},
    game: {
      name = '', date = '', scoreKeeper = '', athleticDirector = '', gameId = '', address = '', events = [],
    },
  } = props;
  const [gameData, setgameData] = useState(game);

  return (
    <Box>
      <Box sx={{ flexGrow: 1 }}>
        <Tabs value="" onChange={handleChange} centered>
          <Tab label="Game Details" />
          <Tab label="Edit Game" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <GameDetails game={game} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          Edit
        </TabPanel>
      </Box>
    </Box>
  );
}

export default GameView;
