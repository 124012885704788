/* eslint-disable prefer-const */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import config from '../../config';

function CampaignEmail() {
  const [email, setEmail] = useState('');
  let [message, setMessage] = useState('');
  const [mailSuccess, setMailSuccess] = useState(false);

  const handleEmail = () => {
    message = `${message} \n\n\n ${email}`;
    console.log('the message', message);
    const request = (url, params = {}, method = 'POST') => {
      const options = {
        method,
      };
      if (method === 'GET') {
        url += `?${(new URLSearchParams(params)).toString()}`;
      } else {
        options.body = JSON.stringify(params);
      }

      return fetch(url, options).then((response) => response.json());
    };
    const post = (url, params) => request(url, params, 'POST');
    post(config.endpoints.devContactEmail, { email, message }).then((response) => {
      if (response.success === true) {
        setMailSuccess(true);
      }
    });
  };

  return (
    <>
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '95%' },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField id="outlined-basic" label="Email Address" variant="outlined" onChange={(e) => setEmail(e.target.value)} />
        <TextField
          fullWidth
          id="standard-basic"
          label="You have Jon Doe's Vote!"
          variant="outlined"
          multiline
          sx={{ display: 'block' }}
          rows={4}
          onChange={(e) => setMessage(e.target.value)}
        />
      </Box>
      <Button fullWidth variant="contained" sx={{ background: '#004b84', width: '50%' }} onClick={() => handleEmail()}>Send Email</Button>
      {
        mailSuccess && (
        <p>
          Your email was successfully delivered! Thank you 😀
          {' '}
          <strong>{email}</strong>
          . Please feel free to email any time.
        </p>
        )
      }
    </>
  );
}
export default CampaignEmail;
