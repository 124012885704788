/* eslint-disable no-shadow */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { useSelector, useDispatch } from 'react-redux';
import ClassListSelector from './ClassListSelector';
import CombinedClassesTable from './CombinedClassesTable';
import './styles/Classes.css';
import highlandGames from '../../data/games/classList';

import {
  updateClasses,
} from '../../slices/gamesDetailsSlice';

function Classes() {
  const dispatch = useDispatch();
  const classes = useSelector((state) => state.gameDetails.classes);
  const gameName = useSelector((state) => state.gameDetails.name);

  const [selectedMenClasses, setSelectedMenClasses] = useState([]);
  const [selectedWomenClasses, setSelectedWomenClasses] = useState([]);

  useEffect(() => {
    const sortedMen = [...selectedMenClasses];
    const sortedWomen = [...selectedWomenClasses];
    sortedMen.sort();
    sortedWomen.sort();
    dispatch(updateClasses({ men: sortedMen, women: sortedWomen }));
  }, [selectedMenClasses, selectedWomenClasses]);

  return (
    <Grid container spacing={2}>
      <Grid xs={12} sm={5} smOffset={1}>
        <p style={{ 'text-align': 'center' }}><strong>Men</strong></p>
        <ClassListSelector
          classes={classes?.men}
          allClasses={highlandGames.men}
          updateClasses={(classes) => setSelectedMenClasses(classes)}
        />
      </Grid>
      <Grid xs={12} sm={5}>
        <p style={{ 'text-align': 'center' }}><strong>Women</strong></p>
        <ClassListSelector
          classes={classes?.women}
          allClasses={highlandGames.women}
          updateClasses={(classes) => setSelectedWomenClasses(classes)}
        />
      </Grid>
      <Grid xs={12} sm={12}>
        <h3><span className="game-name">{gameName}</span> Official Classes</h3>
      </Grid>
      <Grid xs={12} sm={6} smOffset={0}>
        <CombinedClassesTable
          chosenClasses={selectedMenClasses}
          headline="Mens"
          sortDirection="right"
        />
      </Grid>
      <Grid xs={12} sm={6} smOffset={0}>
        <CombinedClassesTable
          chosenClasses={selectedWomenClasses}
          headline="Womens"
          sortDirection="left"
        />
      </Grid>
    </Grid>
  );
}
export default Classes;
