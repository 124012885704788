import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import HelperIcon from '../HelperIcon/HelperIcon';
import * as allClasses from '../../data/games/classList';

import {
  updateDetails,
  updateType,
  updateScoreKeeper,
  updateAthleticDirector,
  updateClasses,
} from '../../slices/gamesDetailsSlice';

export default function Advanced() {
  const gamesTypes = ['Highland Games', 'Strongman Competition'];
  const dispatch = useDispatch();
  const details = useSelector((state) => state.gameDetails.details);
  const type = useSelector((state) => state.gameDetails.type);
  const athleticDirector = useSelector((state) => state.gameDetails.athleticDirector);
  const scoreKeeper = useSelector((state) => state.gameDetails.scoreKeeper);

  const handleGameTypeChange = (gameType) => {
    dispatch(updateType(gameType));
    dispatch(updateClasses(allClasses[gameType]));
  };

  /**
   * @todo:
   * Add additional information to the game details. Like an image, website, etc..
   */

  return (
    <>

      <Paper elevation={2} className="paper-padding form-margin">
        <h3>Games Type</h3>
        <p>
          <Select
            placeholder=""
            fullWidth
            variant="outlined"
            size="medium"
            type="text"
            name="title"
            value={type}
            onChange={(e) => handleGameTypeChange(e.target.value)}
          >
            {gamesTypes.map((game) => <MenuItem key={game} value={game}>{game}</MenuItem>)}
          </Select>
        </p>

      </Paper>

      <Paper elevation={2} className="paper-padding">
        <h3>Officials</h3>
        <Grid container>
          <Grid xs={12} sm={5} className="form-margin">
            {/* <Typography variant="p" style={{ 'font-weight': 'bold' }}>Athletic Director</Typography> */}
            <TextField
              placeholder=""
              label="Athletic Director"
              fullWidth
              variant="outlined"
              size="medium"
              type="text"
              name="title"
              onChange={(e) => dispatch(updateAthleticDirector(e.target.value))}
              value={athleticDirector}
              InputProps={{
                endAdornment: (
                  <HelperIcon
                    title="AD"
                    description="Enter the name of your athletic director. If there are more than one, separate them with a comma."
                  />
                ),
              }}
            />
          </Grid>
          <Grid xs={12} sm={6} smOffset={1} className="form-margin">
            {/* <Typography variant="p" style={{ 'font-weight': 'bold' }}>Score Keeper</Typography> */}
            <TextField
              placeholder=""
              label="Score Keeper"
              fullWidth
              variant="outlined"
              size="medium"
              type="text"
              name="title"
              onChange={(e) => dispatch(updateScoreKeeper(e.target.value))}
              value={scoreKeeper}
              InputProps={{
                endAdornment: (
                  <HelperIcon
                    title="Score Keeper"
                    description="Enter the name of the official score keeper for this games."
                  />
                ),
              }}
            />
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={2} className="paper-padding form-margin">
        <h3>Additional Notes</h3>
        <Grid container>
          <TextField
            placeholder=""
            label="Notes"
            fullWidth
            multiline
            variant="outlined"
            size="medium"
            type="text"
            name="title"
            value={details}
            onChange={(e) => dispatch(updateDetails(e.target.value))}
            InputProps={{
              endAdornment: (
                <HelperIcon
                  title="Notes"
                  description="Store notes about your games here."
                />
              ),
            }}
          />
        </Grid>
      </Paper>

    </>
  );
}
