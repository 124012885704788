import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import events from '../data/games/eventsList';

const initialType = 'Highland Games';

const initialState = {
  id: uuid(),
  name: '',
  address: 'Loch Ness, Scotland',
  address1: '',
  address2: '',
  type: initialType,
  city: '',
  state: '',
  zip: '',
  country: 'United States',
  dates: [],
  judges: [],
  scoreKeeper: '',
  athleticDirector: '',
  details: '',
  classes: { men: [], women: [] },
  athletes: { men: { a: [{}] }, women: { a: [{}] } },
  events: events[initialType],
};

export const gamesDetailsSlice = createSlice({
  name: 'gameDetails',
  initialState,
  reducers: {
    updateName: (state, action) => {
      state.name = action.payload;
    },
    updateAddress: (state, action) => {
      state.address = action.payload;
    },
    updateAddress1: (state, action) => {
      state.address1 = action.payload;
    },
    updateAddress2: (state, action) => {
      state.address2 = action.payload;
    },
    updateCity: (state, action) => {
      state.city = action.payload;
    },
    updateState: (state, action) => {
      state.state = action.payload;
    },
    updateZip: (state, action) => {
      state.zip = action.payload;
    },
    updateCountry: (state, action) => {
      state.country = action.payload;
    },
    updateDates: (state, action) => {
      state.dates = action.payload;
    },
    updateType: (state, action) => {
      state.type = action.payload;
    },
    updateAthleticDirector: (state, action) => {
      state.athleticDirector = action.payload;
    },
    updateDetails: (state, action) => {
      state.details = action.payload;
    },
    updateClasses: (state, action) => {
      state.classes = action.payload;
    },
    updateAthletes: (state, action) => {
      state.athletes = action.payload;
    },
    updateEvents: (state, action) => {
      state.events = action.payload;
    },
    updateJudges: (state, action) => {
      state.judges = action.payload;
    },
    updateScoreKeeper: (state, action) => {
      state.scoreKeeper = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateName,
  updateAddress,
  updateAddress1,
  updateAddress2,
  updateAthletes,
  updateCity,
  updateClasses,
  updateCountry,
  updateDates,
  updateDetails,
  updateEvents,
  updateAthleticDirector,
  updateState,
  updateType,
  updateZip,
  updateJudges,
  updateScoreKeeper,
} = gamesDetailsSlice.actions;
export default gamesDetailsSlice.reducer;
