/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { blue } from '@mui/material/colors';
import HtmlTooltip from '../GlobalTooltip/GlobalTooltip';

export default function MyHelperIcon(props) {
  return (
    <HtmlTooltip
      color="primary"
      arrow
      placement="top"
      title={(
        <>
          <Typography color="inherit" variant="h6">{props.title}</Typography>
          {props.description}
        </>
                   )}
    >
      <IconButton color="primary" aria-label="help icon" component="span" tabIndex="-1">
        <InfoOutlinedIcon sx={{ color: blue[200] }} />
      </IconButton>
    </HtmlTooltip>
  );
}
