const athletes = {
  women: {
    A: [
      {
        firstName: 'Kiera',
        lastName: 'MacKenzie',
        email: 'Kiera.MacKenzie@saaa.org',
        tshirtSize: 'S',
        state: 'Maryland',
        imgUrl: 'https://i.pravatar.cc/300',
      },

      {
        firstName: 'Julie',
        lastName: 'Peters',
        email: 'Julie.Peters@saaa.org',
        tshirtSize: 'M',
        state: 'New York',
        imgUrl: 'https://i.pravatar.cc/300',
      },

      {
        firstName: 'Karen',
        lastName: 'Thompson',
        email: 'Karen.Thompson@saaa.org',
        tshirtSize: 'L',
        state: 'Pennsylvania',
        imgUrl: 'https://i.pravatar.cc/300',
      },

      {
        firstName: 'Ellen',
        lastName: 'Hall',
        email: 'Ellen.Hall@saaa.org',
        tshirtSize: 'XL',
        state: 'Massachusetts',
        imgUrl: 'https://i.pravatar.cc/300',
      },

      {
        firstName: 'Gwen',
        lastName: 'Smith',
        email: 'Gwen.Smith@saaa.org',
        tshirtSize: 'S',
        state: 'Michigan',
        imgUrl: 'https://i.pravatar.cc/300',
      },

      {
        firstName: 'Anne',
        lastName: 'Campbell',
        email: 'Anne.Campbell@saaa.org',
        tshirtSize: 'M',
        state: 'Illinois',
        imgUrl: 'www.saaa.org/AnneCampbell.jpg',
      },

      {
        firstName: 'Rebecca',
        lastName: 'Davies',
        email: 'Rebecca.Davies@saaa.org',
        tshirtSize: 'L',
        state: 'Ohio',
        imgUrl: 'https://i.pravatar.cc/300',
      },

      {
        firstName: 'Lucy',
        lastName: 'Oliver',
        email: 'Lucy.Oliver@saaa.org',
        tshirtSize: 'XL',
        state: 'California',
        imgUrl: 'https://i.pravatar.cc/300',
      },

      {
        firstName: 'Donna',
        lastName: 'King',
        email: 'Donna.King@saaa.org',
        tshirtSize: 'S',
        state: 'North Carolina',
        imgUrl: 'https://i.pravatar.cc/300',
      },

      {
        firstName: 'Kathy',
        lastName: 'Adams',
        email: 'Kathy.Adams@saaa.org',
        tshirtSize: 'M',
        state: 'Virginia',
        imgUrl: 'https://i.pravatar.cc/300',
      },
    ],
  },
  men: {
    A: [
      {
        firstName: 'David',
        lastName: 'McDonald',
        email: 'David.McDonald@saaa.org',
        tshirtSize: 'S',
        state: 'Maryland',
        imgUrl: 'https://i.pravatar.cc/300',
      },

      {
        firstName: 'Robert',
        lastName: 'Price',
        email: 'Robert.Price@saaa.org',
        tshirtSize: 'M',
        state: 'New York',
        imgUrl: 'https://i.pravatar.cc/300',
      },

      {
        firstName: 'Daniel',
        lastName: 'Ryan',
        email: 'Daniel.Ryan@saaa.org',
        tshirtSize: 'L',
        state: 'Pennsylvania',
        imgUrl: 'https://i.pravatar.cc/300',
      },

      {
        firstName: 'Thomas',
        lastName: 'Smith',
        email: 'Thomas.Smith@saaa.org',
        tshirtSize: 'XL',
        state: 'Massachusetts',
        imgUrl: 'https://i.pravatar.cc/300',
      },

      {
        firstName: 'Steven',
        lastName: 'Gray',
        email: 'Steven.Gray@saaa.org',
        tshirtSize: 'S',
        state: 'Michigan',
        imgUrl: 'https://i.pravatar.cc/300',
      },

      {
        firstName: 'Michael',
        lastName: 'Howard',
        email: 'Michael.Howard@saaa.org',
        tshirtSize: 'M',
        state: 'Illinois',
        imgUrl: 'https://i.pravatar.cc/300',
      },

      {
        firstName: 'Christopher',
        lastName: 'Hall',
        email: 'Christopher.Hall@saaa.org',
        tshirtSize: 'L',
        state: 'Ohio',
        imgUrl: 'https://i.pravatar.cc/300',
      },

      {
        firstName: 'John',
        lastName: 'Adams',
        email: 'John.Adams@saaa.org',
        tshirtSize: 'XL',
        state: 'California',
        imgUrl: 'https://i.pravatar.cc/300',
      },

      {
        firstName: 'Andrew',
        lastName: 'Taylor',
        email: 'Andrew.Taylor@saaa.org',
        tshirtSize: 'S',
        state: 'North Carolina',
        imgUrl: 'https://i.pravatar.cc/300',
      },

      {
        firstName: 'Richard',
        lastName: 'Carpenter',
        email: 'Richard.Carpenter@saaa.org',
        tshirtSize: 'M',
        state: 'Virginia',
        imgUrl: 'https://i.pravatar.cc/300',
      },
    ],
  },
};

const getMockAthletes = (cls = 'a', gndr = 'women') => {
  if (athletes?.[gndr]?.[cls]) return athletes?.[gndr]?.[cls];
  return [];
};

export default getMockAthletes;
