import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import GameView from '../GameView/GameView';
import styles from './GamesList.css';

const VISIBLE_FIELDS = ['name', 'date', 'scoreKeeper', 'athleticDirector', 'gameId', 'address'];

const columnsData = [
  {
    field: 'name',
    headerName: 'Name',
    width: 600,
  },
  {
    field: 'date',
    headerName: 'Date',
  },
  {
    field: 'scoreKeeper',
    headerName: 'Score Keeper',
    width: 150,
  },
  {
    field: 'athleticDirector',
    headerName: 'Athletic Director',
    width: 150,
  },
  {
    field: 'gameId',
    headerName: 'Game ID',
    width: 150,
  },
  {
    field: 'address',
    headerName: 'address',
    width: 300,
  },
];
const rowsData = [
  {
    name: 'Game 1',
    date: '2021-10-01',
    scoreKeeper: 'John Doe',
    athleticDirector: 'Jane Doe',
    id: '1', // set to be the same
    gameId: '1', //
    address: '1234 Main St, San Diego, CA 92101',
    events: ['Weight Over Bar', 'Light Weight For Distance', 'Light Weight For Distance', 'Heavy Hammer', 'Light Hammer', 'Caber', 'Sheaf', 'Braemar Stone'],
    classes: { men: ['a', 'b', 'c', 'Novice', 'Light Weight'], women: ['a', 'b', 'c', 'Novice', 'Light Weight'] },
  },
  {
    name: 'San Diego ScotsFest 2021',
    date: '2021-10-01',
    scoreKeeper: 'Miranda Johnson',
    athleticDirector: 'Sean Smith',
    id: 'hg-001-0212-22', // set to be the same
    gameId: 'hg-001-0212-22', //
    address: '1234 Main St, San Diego, CA 92101',
    events: ['Weight Over Bar', 'Light Weight For Distance', 'Light Weight For Distance', 'Heavy Hammer', 'Light Hammer', 'Caber', 'Sheaf', 'Braemar Stone', 'Open Stone'],
    classes: { men: ['a', 'b', 'c', 'Novice', 'Light Weight'], women: ['a', 'b', 'c', 'Novice', 'Light Weight'] },
  },
  {
    name: 'Highland Games of the Rockies',
    date: '2021-10-02',
    scoreKeeper: 'John Doe',
    athleticDirector: 'Jane Doe',
    id: 'hg-002-0212-22', // set to be the same
    gameId: 'hg-002-0212-22', //
    address: '5678 Main St, Denver, CO 80202',
    events: ['Weight Over Bar', 'Light Weight For Distance', 'Light Weight For Distance', 'Heavy Hammer', 'Light Hammer', 'Caber', 'Sheaf', 'Braemar Stone', 'Open Stone'],
    classes: { men: ['a', 'b', 'c', 'Novice', 'Light Weight'], women: ['a', 'b', 'c', 'Novice', 'Light Weight'] },
  },
  {
    name: 'Highland Games of the Deep South', date: '2021-10-15', scoreKeeper: 'Miranda Johnson', athleticDirector: 'Sean Smith', id: 'hg-015-0212-22', gameId: 'hg-015-0212-22', address: '3334 Main St, New Orleans, LA 70112', events: ['Weight Over Bar', 'Light Weight For Distance', 'Light Weight For Distance', 'Heavy Hammer', 'Light Hammer', 'Caber', 'Sheaf', 'Braemar Stone', 'Open Stone'], classes: { men: ['a', 'b', 'c', 'Novice', 'Light Weight'], women: ['a', 'b', 'c', 'Novice', 'Light Weight'] },
  }, {
    name: 'Highland Games of the Upper Midwest', date: '2021-10-16', scoreKeeper: 'John Doe', athleticDirector: 'Jane Doe', id: 'hg-016-0212-22', gameId: 'hg-016-0212-22', address: '3536 Main St, Minneapolis, MN 55402', events: ['Weight Over Bar', 'Light Weight For Distance', 'Light Weight For Distance', 'Heavy Hammer', 'Light Hammer', 'Caber', 'Sheaf', 'Braemar Stone', 'Open Stone'], classes: { men: ['a', 'b', 'c', 'Novice', 'Light Weight'], women: ['a', 'b', 'c', 'Novice', 'Light Weight'] },
  }, {
    name: 'Highland Games of the Central Plains', date: '2021-10-17', scoreKeeper: 'Miranda Johnson', athleticDirector: 'Sean Smith', id: 'hg-017-0212-22', gameId: 'hg-017-0212-22', address: '3738 Main St, Kansas City, MO 64101', events: ['Weight Over Bar', 'Light Weight For Distance', 'Light Weight For Distance', 'Heavy Hammer', 'Light Hammer', 'Caber', 'Sheaf', 'Braemar Stone', 'Open Stone'], classes: { men: ['a', 'b', 'c', 'Novice', 'Light Weight'], women: ['a', 'b', 'c', 'Novice', 'Light Weight'] },
  }, {
    name: 'Highland Games of the Appalachian Region', date: '2021-10-18', scoreKeeper: 'John Doe', athleticDirector: 'Jane Doe', id: 'hg-018-0212-22', gameId: 'hg-018-0212-22', address: '3940 Main St, Asheville, NC 28801', events: ['Weight Over Bar', 'Light Weight For Distance', 'Light Weight For Distance', 'Heavy Hammer', 'Light Hammer', 'Caber', 'Sheaf', 'Braemar Stone', 'Open Stone'], classes: { men: ['a', 'b', 'c', 'Novice', 'Light Weight'], women: ['a', 'b', 'c', 'Novice', 'Light Weight'] },
  }, {
    name: 'Highland Games of the Rocky Mountain Region', date: '2021-10-19', scoreKeeper: 'Miranda Johnson', athleticDirector: 'Sean Smith', id: 'hg-019-0212-22', gameId: 'hg-019-0212-22', address: '4142 Main St, Salt Lake City, UT 84101', events: ['Weight Over Bar', 'Light Weight For Distance', 'Light Weight For Distance', 'Heavy Hammer', 'Light Hammer', 'Caber', 'Sheaf', 'Braemar Stone', 'Open Stone'], classes: { men: ['a', 'b', 'c', 'Novice', 'Light Weight'], women: ['a', 'b', 'c', 'Novice', 'Light Weight'] },
  }, {
    name: 'Highland Games of the Texas Region', date: '2021-10-20', scoreKeeper: 'John Doe', athleticDirector: 'Jane Doe', id: 'hg-020-0212-22', gameId: 'hg-020-0212-22', address: '4344 Main St, Austin, TX 78701', events: ['Weight Over Bar', 'Light Weight For Distance', 'Light Weight For Distance', 'Heavy Hammer', 'Light Hammer', 'Caber', 'Sheaf', 'Braemar Stone', 'Open Stone'], classes: { men: ['a', 'b', 'c', 'Novice', 'Light Weight'], women: ['a', 'b', 'c', 'Novice', 'Light Weight'] },
  }, {
    name: 'Highland Games of the New England Region', date: '2021-10-21', scoreKeeper: 'Miranda Johnson', athleticDirector: 'Sean Smith', id: 'hg-021-0212-22', gameId: 'hg-021-0212-22', address: '4546 Main St, Providence, RI 02903', events: ['Weight Over Bar', 'Light Weight For Distance', 'Light Weight For Distance', 'Heavy Hammer', 'Light Hammer', 'Caber', 'Sheaf', 'Braemar Stone', 'Open Stone'], classes: { men: ['a', 'b', 'c', 'Novice', 'Light Weight'], women: ['a', 'b', 'c', 'Novice', 'Light Weight'] },
  }, {
    name: 'Highland Games of the Mid-South Region', date: '2021-10-22', scoreKeeper: 'John Doe', athleticDirector: 'Jane Doe', id: 'hg-022-0212-22', gameId: 'hg-022-0212-22', address: '4748 Main St, Nashville, TN 37201', events: ['Weight Over Bar', 'Light Weight For Distance', 'Light Weight For Distance', 'Heavy Hammer', 'Light Hammer', 'Caber', 'Sheaf', 'Braemar Stone', 'Open Stone'], classes: { men: ['a', 'b', 'c', 'Novice', 'Light Weight'], women: ['a', 'b', 'c', 'Novice', 'Light Weight'] },
  }, {
    name: 'Highland Games of the Far West Region', date: '2021-10-23', scoreKeeper: 'Miranda Johnson', athleticDirector: 'Sean Smith', id: 'hg-023-0212-22', gameId: 'hg-023-0212-22', address: '4950 Main St, San Francisco, CA 94102', events: ['Weight Over Bar', 'Light Weight For Distance', 'Light Weight For Distance', 'Heavy Hammer', 'Light Hammer', 'Caber', 'Sheaf', 'Braemar Stone', 'Open Stone'], classes: { men: ['a', 'b', 'c', 'Novice', 'Light Weight'], women: ['a', 'b', 'c', 'Novice', 'Light Weight'] },
  }, {
    name: 'Highland Games of the Mid-Atlantic Coastal Region', date: '2021-10-24', scoreKeeper: 'John Doe', athleticDirector: 'Jane Doe', id: 'hg-024-0212-22', gameId: 'hg-024-0212-22', address: '5152 Main St, Virginia Beach, VA 23451', events: ['Weight Over Bar', 'Light Weight For Distance', 'Light Weight For Distance', 'Heavy Hammer', 'Light Hammer', 'Caber', 'Sheaf', 'Braemar Stone', 'Open Stone'], classes: { men: ['a', 'b', 'c', 'Novice', 'Light Weight'], women: ['a', 'b', 'c', 'Novice', 'Light Weight'] },
  },
];

export default function Games() {
  const [fields, setFields] = useState(VISIBLE_FIELDS);
  const [cols, setCols] = useState(columnsData);
  const [rows, setRows] = useState(rowsData);
  const [editing, setEditing] = useState(false);
  const [selectedGame, setSelectedGame] = useState({});
  const [gamelistHeight, setGameListHeight] = useState('100%');
  const {
    judge = false, athlete = false, athleticDirector = false, name = false, search = false,
  } = useParams();

  const data = {
    columns: cols,
    rows,
  };
  const [searchTerm, setSearchTerm] = useState(search || null);

  const handleGameSelection = (game) => {
    console.log(game);
    setRows([game]);
    setSelectedGame(game);
    setEditing(true);
    setGameListHeight(210);
  };

  const handleViewDifferentGame = () => {
    setEditing(false);
    setRows(rowsData);
    setGameListHeight('100%');
  };

  console.log('data', data);

  // Otherwise filter will be applied on fields such as the hidden column id
  const columns = React.useMemo(
    () => data.columns.filter((column) => fields.includes(column.field)),
    [data.columns],
  );

  return (
    <Box sx={{ height: gamelistHeight, width: '100%' }}>
      <DataGrid
        {...data}
        disableColumnFilter
        rowReorderIcon
        disableDensitySelector
        columns={columns}
        onCellClick={(params) => handleGameSelection(params.row)}
        slots={{ toolbar: GridToolbar }}
        initialState={{
          ...data.initialState,
          filter: {
            filterModel: {
              items: [],
              quickFilterValues: [searchTerm],
            },
          },
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500, placeholder: 'Search Games by name, id, address, athletic director etc...' },
          },
        }}
        footer={<>Test</>}
      />
      {editing && (
        <>
          <Button variant="contained" sx={{ 'margin-top': 10 }} onClick={() => handleViewDifferentGame()}>View a different game</Button>
          <GameView game={selectedGame} />
        </>
      )}
    </Box>
  );
}
