/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { Typography } from '@mui/material';

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export default function GameDatesList(props) {
  const { dates, removeDate } = props;
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);

  return (
    <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
      <Grid container spacing={2}>

        <Grid item xs={12} md={6}>
          <Demo>
            <List fullWidth dense={dense}>
              {
                dates?.map((date, i) => (
                  <ListItem
                    secondaryAction={(
                      <IconButton edge="end" aria-label="delete" onClick={() => removeDate(date)}>
                        <EventBusyIcon fontSize="small" />
                      </IconButton>
                  )}
                  >
                    <Typography variant="h6" component="div">
                      <strong>Day</strong>
                      {' '}
                      {i + 1}
                      :
                      {' '}
                      {date.day}
                      /
                      {date.month}
                      /
                      {date.year}
                    </Typography>
                  </ListItem>
                ))
              }
            </List>
          </Demo>
        </Grid>
      </Grid>
    </Box>
  );
}
