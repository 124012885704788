import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Navigation from '../components/Navigation/Navigation';

// Set up grid
function Root() {
  const location = useLocation();
  return (
    <>
      <Navigation />
      <div className="main-content">
        <Outlet key={location.pathname} />
      </div>
    </>
  );
}
export default Root;
