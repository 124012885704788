const highlandGames = {
  men: [
    'A',
    'B',
    'C',
    'Open',
    'LightWeight',
    'Novice',
    'Masters',
    'Masters 50',
    'Masters 60',
    'World Champion',
    'Pro',
  ],
  women: [
    'A',
    'B',
    'C',
    'Open',
    'LightWeight',
    'Novice',
    'Masters',
    'Masters 50',
    'Masters 60',
    'World Champion',
    'Pro',
  ],
};
export default highlandGames;
