import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import caber1 from '../shared/images/highlands-caber-1.png';

function InDev() {
  return (
    <Grid container>
      <Grid xs={12} sm={6} smOffset={3}>
        <h1>HG Scores</h1>
        <p>Modern Scoring software for the Highland Games community.</p>
        {/* <p>Hello! I'm a Highlands Athlete.</p>
        <p>
          I'm looking to improve the software, and tools available to highlands athletes. Centralized scoring software is the first task I'd like to tackle. I'm looking for feedback, and help in testing the software. If you're interested in helping, please click Contact above. There will be bugs, and visual aberrations to be expected as we crawl closer to a releasable version.
        </p>

        <p>
          I'd like to contribute a mobile friendly, easy to use, advanced set of tools that help support the community. This work initially is to support the <a href="http://www.saaa-national.org" target="_blank" rel="noreferrer">SAAA</a> (Scottish American Athletic Association) - but will be an open platform for the Highlands Games community world-wide to use.
        </p>

        <h3>
          Features
        </h3>
        <p>Below is a list of some of the features and benefits of this scoring software over others. If you have questions, comments, or want to get your hands on the software to help us test or explore, please click Contact above.</p>
        <ul>
          <li>Create games in advance! No information is required. You can create placeholders for games in advance. Add athletes, and update events / scores at any time. The system is freeform, but provides guidrails around incorrect scoring.</li>
          <li>
            Multi-Cast integration for score keeping. Endpoints for scores is configurable - Post to NASGA, CaberMetrics, or your own custom platform. (Note: NASGA is currently
            {' '}
            <strong>not</strong>
            {' '}
            a partner).
          </li>
          <li>Created using modern technologies. This means updates, maintenance and feature enhancements are quick and easy. It doesn't matter what device or screen your are on.</li>
          <li>HG Scores works online, or offline with offline backup. Backup any game or athlete datasheet to an excel document.</li>
          <li>Real-time scoring. Watch scores and events in real time. For any given game, as it's details are updated, spectators and fans can see in real-time scores. For even better results, Judges can enter and edit their scores from a phone or tablet in real-time. Each game has it's own URL, you can watch stats update in real time.</li>
          <li>Creating and editing games and athletes is easy with a modern, fast ui, mobile friendly UI.</li>
          <li>Athletes are stored in a database, and can be quickly added to new games.</li>
          <li>Communicate with your athletes. Create a game within HGScore. Once completed, at the click of a button email all atheletes in all classes with a custom message, and information. Information about each athlete is tracked including their email address and t-shirt size.</li>
          <li>Lots more....</li>
        </ul> */}
      </Grid>
      <Grid xs={12} sm={6} smOffset={3}>
        <img src={caber1} alt="" style={{ 'max-width': '100%' }} />
      </Grid>
    </Grid>
  );
}
export default InDev;
